<template>
	<div class="grid layout-main">
		<Toast />
		<Dialog header="Info about tests" :modal="true" v-model:visible="showInfoAboutTests" :breakpoints="{'960px': '75vw', '640px': '100vw'}" :style="{width: '50vw', height: '70%'}">
			<TabView>
				<TabPanel v-for="(sectionValues, sectionKey) in info" :key="sectionKey" :header="infoPages[sectionKey]">
					<div v-for="(value, key) in sectionValues" :key="key">
						<p><b>{{key}}</b></p>
							<div class="grid" v-for="(propValue, propKey) in value" :key="propKey">
								<div class="col-8">{{propValue.name}}</div>
								<div class="col-4">
									<span v-if="propValue.value != null && propValue.value.length>0">{{propValue.value}}</span>
									<span v-else>-</span>
								</div>
							</div>
						<hr />
					</div>
				</TabPanel>
			</TabView>
			<template #footer>
				<Button label="Close" icon="pi pi-check" autofocus @click="showInfoAboutTests=false" />
			</template>
		</Dialog>

		<div class="col-3 flex flex-column">
			<div class="card flex-grow-1 mb-0" style="padding-top: 0; padding-left: 0.5rem; padding-right: 0.5rem;">
				<TabView v-model:activeIndex="activeTab">
					<TabPanel header="File">
						<FileUpload mode="basic" name="dataFile" accept=".csv,.xlsx" :url="baseUrl + '/process/upload?var_name=' + varNames[selectedVar] + '?var_type=' + selectedType.code" @error="onError" @before-upload="onUploadStart" @progress="onProgress" @upload="onBasicUpload" :auto="true" class="p-button-warning" style="width:100%" />
						<ProgressBar :value="uploadProgress" class="mt-3">
							{{uploadProgress}}%
						</ProgressBar>
						<div class="mt-4"><h3>Variable name</h3></div>
						<div class="flex mb-2" v-for="(value, key) in varNames" :key="key">
							<div class="flex-grow-1 ml-4">{{key}}</div><div><RadioButton @change="onVarChanged" name="checkedVar" :value="key" v-model="selectedVar" :binary="true" /></div>
						</div>
						<div class="mt-2 mb-2"><h3>Type</h3></div>
						<div class="flex mb-2">
							<div class="flex-grow-1 ml-4">
								<Dropdown v-model="selectedType" @change="onTypeChanged" :options="types" optionLabel="name" placeholder="Select a type" style="width:100%" />
							</div>
						</div>
					</TabPanel>
					<TabPanel header="Influx DB">
						<div style="display: grid; grid-template-columns: auto 1fr; gap: 10px; align-items: center;">
							<label for="fromDate">From:</label> <Calendar id="fromDate" v-model="startDate" showTime hourFormat="24" />
							<label for="toDate">To:</label> <Calendar id="toDate" v-model="endDate" showTime hourFormat="24" />
						</div>
						<div class="mt-2 mb-2"><h3>Program name</h3></div>
						<div class="flex mb-2">
							<div class="flex-grow-1 ml-4">
								<Dropdown v-model="selectedInfluxProgram" :options="influxPrograms" placeholder="Select a program" style="width:100%" />
							</div>
						</div>
						<div class="mt-2 mb-2"><h3>Variable name</h3></div>
						<div class="flex mb-2">
							<div class="flex-grow-1 ml-4">
								<Dropdown v-model="selectedInfluxVar" :options="influxVars" placeholder="Select a variable" style="width:100%" />
							</div>
						</div>
						<div class="mt-2 mb-2"><h3>States</h3></div>
						<div class="flex mb-2" v-for="(value, key) in influxFlagNames" :key="key">
							<div class="flex-grow-1 ml-4">{{value}}</div><div><Checkbox name="influxFlag" :value="value" v-model="selectedInfluxFlags" /></div>
						</div>
						<div class="flex mt-4">
							<Button label="Paint" class="flex-grow-1" @click="onInfluxPaint" />
						</div>
					</TabPanel>
				</TabView>
			</div>
		</div>

		<div class="col-3 flex flex-column" v-if="activeTab==0">
			<div class="card flex-grow-1">
				<div class="flex justify-content-between">
					<div>
						<span class="block text-500 font-medium mb-3">File</span>
						<div class="text-900 font-medium text-xl">{{fileName}}</div>
					</div>
					<div class="flex align-items-center justify-content-center bg-blue-100 border-round" style="width:2.5rem;height:2.5rem">
						<i class="pi pi-file text-blue-500 text-xl"></i>
					</div>
				</div>
			</div>
			<div class="card flex-grow-1">
				<div class="flex justify-content-between">
					<div>
						<span class="block text-500 font-medium mb-3">Part identification</span>
						<div class="text-900 font-medium text-xl">{{partName}}</div>
					</div>
					<div class="flex align-items-center justify-content-center bg-orange-100 border-round" style="width:2.5rem;height:2.5rem">
						<i class="pi pi-box text-orange-500 text-xl"></i>
					</div>
				</div>
			</div>
			<div class="card flex-grow-1">
				<div class="flex justify-content-between">
					<div>
						<span class="block text-500 font-medium mb-3">Date</span>
						<div class="text-900 font-medium text-xl">{{date}}</div>
					</div>
					<div class="flex align-items-center justify-content-center bg-cyan-100 border-round" style="width:2.5rem;height:2.5rem">
						<i class="pi pi-calendar text-cyan-500 text-xl"></i>
					</div>
				</div>
			</div>
			<div class="card flex-grow-1">
				<div class="flex justify-content-between">
					<div>
						<span class="block text-500 font-medium mb-3">Material</span>
						<div class="text-900 font-medium text-xl">{{material}}</div>
					</div>
					<div class="flex align-items-center justify-content-center bg-purple-100 border-round" style="width:2.5rem;height:2.5rem">
						<i class="pi pi-th-large text-purple-500 text-xl"></i>
					</div>
				</div>
			</div>
			<div class="card flex-grow-1 mb-0">
				<div class="flex justify-content-between">
					<div>
						<span class="block text-500 font-medium mb-3">Nozzle diameter</span>
						<div class="text-900 font-medium text-xl">{{nozzleDiameter}} mm</div>
					</div>
					<div class="flex align-items-center justify-content-center bg-green-100 border-round" style="width:2.5rem;height:2.5rem">
						<i class="pi pi-circle text-green-500 text-xl"></i>
					</div>
				</div>
			</div>
		</div>
		<div class="flex flex-column" :class="{ 'col-6': activeTab==0, 'col-9': activeTab==1 }">
			<div class="card flex-grow-1 mb-0">
				<div style="position: relative; height: 100%;">
					<div style="position: absolute; left: 0; right: 0;">
						<Button label="Info about tests" style="z-index: 1" :disabled="info==null" :loading="loadingInfo" @click="showInfoAboutTests=true"></Button>
					</div>
					<div style="width:100%;height:calc(100% - 2em);" id="plotlyDiv" ref="plotlyDiv">
					</div>			
					<div v-if="loading" style="background: rgba(255, 255, 255, 0.4); position: absolute; top: 0; left: 0; bottom: 0; right: 0;">
						<div class="flex justify-content-center flex-wrap card-container" style="height: 100%">
							<div class="flex align-items-center justify-content-center">
								<ProgressSpinner />
							</div>
						</div>
					</div>
					<div class="flex flex-grow-1 flex-column" style="height: 2em;">
						<div class="flex flex-row align-items-center flex-grow-1">
							<div class="flex flex-grow-0 align-items-center justify-content-center mr-4">1</div>
							<Slider class="flex flex-grow-1 align-items-center justify-content-center" @slideend="onLayerChangeEnd" v-model="layer" :min="minLayer" :max="maxLayer"></Slider>
							<div class="flex flex-grow-0 align-items-center justify-content-center ml-4">{{maxLayer}}</div>
						</div>
						<div class="flex flex-column align-items-center">
							<div class="flex align-items-center">Layer #{{layer}}</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Plotly from 'plotly.js-gl3d-dist';
import axios from 'axios';

export default {
	data(){
		return {
			showInfoAboutTests: false,
			info: null,
			infoPages: {
				'ada_one': 'AdaOne',
				'hmi': 'HMI Controls/Indicators'
			},
			selectedType: null,
			selectedInfluxVar: null,
			selectedInfluxProgram: null,
			selectedInfluxFlags: null,
			influxFlagNames: ['Espera', 'Error', 'Mov_no_aditvando', 'aditivando', 'Total', 'DPM_OnOFF'],
			types: [
				{name: 'Set point (S)', code: 'S'},
				{name: 'Measurement (M)', code: 'M'},
				{name: 'Error (E)', code: 'E'}
			],
			varNames: {
				'Temperature zone 1': 'Temp Zone 1',
				'Temperature zone 2': 'Temp Zone 2',
				'Temperature zone 3': 'Temp Zone 3',
				'Bed temperature': 'Temp Bed',
				'Environment temperature': 'Enviroment Temp',
				'Environment humidity': 'Humidity',
				'Extruding speed': 'Extruder speed',
				'Forward speed': 'Forward speed'
			},
			fileName: '',
			partName: '',
			date: '',
			material: '',
			nozzleDiameter: 0,
			uploadProgress: 0,
			selectedVar: null,
			layer: 1,
			minLayer: 1,
			maxLayer: 1,
			loading: false,
			loadingInfo: false,
			startDate: null,
			endDate: null,
			lastStartDate: null,
			lastEndDate: null,
			activeTab: 0,
			influxVars: [],
			influxPrograms: [],
			baseUrl: process.env.VUE_APP_REST_BASE_URL
		}
	},
	beforeMount: function(){
		this.selectedVar = Object.keys(this.varNames)[0];
		this.selectedType = this.types[0];
	},
	mounted: function(){
		this.refreshData(-1);
		this.refreshInfo();
		this.fetchInfluxPrograms();
	},
	methods: {
		parseResponse(parsedResponse){
			if ('error' in parsedResponse) return;
			this.influxVars = parsedResponse.columns;
			this.fileName = parsedResponse.file_name;
			this.nozzleDiameter = parsedResponse.nozzle_diameter;
			this.partName = parsedResponse.part_name;
			this.date = parsedResponse.date_time;
			this.material = parsedResponse.material;
			this.layer = parsedResponse.sel_layer;
			this.minLayer = parsedResponse.min_layer;
			this.maxLayer = parsedResponse.max_layer;
			var parsedFigure = JSON.parse(parsedResponse.figure);
			Plotly.newPlot(
				this.$refs["plotlyDiv"],
				parsedFigure.data,
				parsedFigure.layout,
				{ responsive: true }
			);
		},
		onUploadStart(){
			this.loading = true;
			this.uploadProgress = 0;
		},
		onBasicUpload(event){
			console.log(event);
			var parsedResponse = JSON.parse(event.xhr.response);
			if ('message' in parsedResponse){
				this.loading = false;
				this.$toast.add({
					severity: "error",
					summary: "Error",
					detail: "Error uploading data to the server",
					life: 3000,
				});
			}else{
				if (parsedResponse.file_type == '.csv')
					this.parseResponse(parsedResponse);
				else
					this.info = parsedResponse.info;
				this.loading = false;
			}
		},
		onError(){
			console.log("Something went wrong");
			this.loading = false;
			this.$toast.add({
				severity: "error",
				summary: "Error",
				detail: "Error uploading data to the server",
				life: 3000,
			});
		},
		onProgress(event){
			this.uploadProgress = event.progress;
		},
		fetchInfluxPrograms(){
			axios
				.get('/process/influx-programs')
				.then(response => {
					this.influxPrograms = response.data.programs;
				})
				.catch(() => {
					this.loadingInfo = false;
					this.$toast.add({
						severity: "error",
						summary: "Error",
						detail: "Error getting program names from server",
						life: 3000,
					});
				});
		},
		refreshInfo(){
			this.loadingInfo = true;
			axios
				.get('/process/test-info')
				.then(response => {
					this.info = response.data.info;
					this.loadingInfo = false;
				})
				.catch(() => {
					this.loadingInfo = false;
					this.$toast.add({
						severity: "error",
						summary: "Error",
						detail: "Error getting data from server",
						life: 3000,
					});
				});
		},
		refreshData(layer){
			this.loading = true;
			axios
				.get('/process/data?var_name=' + this.varNames[this.selectedVar] + '&var_type=' + this.selectedType.code + '&layer=' + layer)
				.then(response => {
					this.parseResponse(response.data);
					this.loading = false;
				})
				.catch(() => {
					this.loading = false;
					this.$toast.add({
						severity: "error",
						summary: "Error",
						detail: "Error getting data from server",
						life: 3000,
					});
				});
		},
		onInfluxPaint(){
			this.loading = true;
			var layer = this.layer;
			console.log(this.influxFlagNames);
			console.log(this.selectedInfluxFlags);
			if (this.lastStartDate == null || this.lastEndDate == null || this.lastStartDate != this.startDate || this.lastEndDate != this.endDate)
				layer = -1;
			var startDate = '';
			var endDate = '';
			if (this.startDate != null)
				startDate = this.startDate.toISOString();
			if (this.endDate != null)
				endDate = this.endDate.toISOString();
			axios
				.get('/process/influx-data?var_name=' + this.selectedInfluxVar + '&start_date=' + startDate + '&end_date=' + endDate + '&program=' + this.selectedInfluxProgram + '&flags=' + this.selectedInfluxFlags.join() + '&layer=' + layer)
				.then(response => {
					this.parseResponse(response.data);
					this.loading = false;
					this.lastStartDate = this.startDate;
					this.lastEndDate = this.endDate;
				})
				.catch(() => {
					this.loading = false;
					this.$toast.add({
						severity: "error",
						summary: "Error",
						detail: "Error getting data from server",
						life: 3000,
					});
				});
		},
		onVarChanged(){
			this.refreshData(this.layer);
		},
		onTypeChanged(){
			this.refreshData(this.layer);
		},
		onLayerChangeEnd(){
			console.log(this.activeTab);
			if (this.activeTab == 0)
				this.refreshData(this.layer);
			else
				this.onInfluxPaint();
		}
	}
}
</script>